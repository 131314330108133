import React, { useState } from 'react';
import { DiscordLogoIcon, HeartFilledIcon, HamburgerMenuIcon, Cross1Icon } from '@radix-ui/react-icons';
import './App.css';
import logo from './logo.svg';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleJoinDiscord = () => {
    window.open("https://discord.gg/aQJbXTsKxF", "_blank");
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <div className="header-content">
            <div className="logo">
              <img src={logo} width={40} height={40} alt="VenomLog Logo" className="logo-img" />
              <h1 className="logo-text">VenomLog</h1>
            </div>
            <nav className={`main-nav ${isMenuOpen ? 'is-open' : ''}`}>
              <a href="#" className="nav-link">Home</a>
              <a href="#" className="nav-link">About</a>
              <a href="#" className="nav-link">Contact</a>
              <button onClick={handleJoinDiscord} className="join-discord-btn">
                Join Discord
              </button>
            </nav>
            <button className="menu-toggle" onClick={toggleMenu}>
              {isMenuOpen ? <Cross1Icon className="icon" /> : <HamburgerMenuIcon className="icon" />}
            </button>
          </div>
        </div>
      </header>

      <main className="App-main">
        <div className="container main-content">
          <h2 className="main-title">We're Redesigning!</h2>
          <p className="main-description">
            VenomLog is currently undergoing a major redesign to bring you an even better experience. We'll be back soon with exciting new features!
          </p>
          <div className="loader"></div>
          <button 
            onClick={handleJoinDiscord}
            className="discord-btn"
          >
            <DiscordLogoIcon className="discord-icon" /> Join Our Discord
          </button>
        </div>
      </main>

      <footer className="App-footer">
        <div className="container">
          <div className="footer-content">
            <div className="footer-love">
              <HeartFilledIcon className="heart-icon" />
              <span>Created with love by the VenomLog Team</span>
            </div>
            <div className="footer-links">
              <a href="#" className="footer-link">Terms of Service</a>
              <a href="#" className="footer-link">Privacy Policy</a>
              <a href="#" className="footer-link">Contact Us</a>
            </div>
            <p className="footer-copyright">© {new Date().getFullYear()} VenomLog. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
